<template>
  <v-form>
    <v-card
      max-width="800px"
      class="mt-4 mx-auto"
      :loading="loading"
      :disabled="loading"
    >
      <v-card-title v-text="$t('SpeakerAccount')" />
      <v-card-text>
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              :error-messages="emailErrors"
              :label="$t('email')"
              @blur="$v.item.email.$touch()"
              @input="$v.item.email.$touch()"
              required
              v-model="item.email"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-checkbox
              :error-messages="enabledErrors"
              :label="$t('enabled')"
              @blur="$v.item.enabled.$touch()"
              @input="$v.item.enabled.$touch()"
              v-model="item.enabled"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="10">
            <v-text-field
              :label="$t('Title')"
              v-model="item.title"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10">
            <InputEditor
              v-model="item.vita"
              :error-messages="vitaErrors"
              :label="$t('speakerVita')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h3>{{ this.$t("Address") }}</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-select
              :error-messages="typeErrors"
              :label="$t('addressType')"
              @blur="$v.item.address.type.$touch()"
              @input="$v.item.address.type.$touch()"
              required
              :items="typeValues"
              v-model="item.address.type"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" sm="6">
            <v-text-field
              :error-messages="streetNrErrors"
              :label="$t('streetNr')"
              @blur="$v.item.address.streetNr.$touch()"
              @input="$v.item.address.streetNr.$touch()"
              v-model="item.address.streetNr"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-text-field
              :error-messages="additionalErrors"
              :label="$t('additional')"
              @blur="$v.item.address.additional.$touch()"
              @input="$v.item.address.additional.$touch()"
              v-model="item.address.additional"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" sm="4">
            <v-text-field
              :error-messages="zipCodeErrors"
              :label="$t('zipCode')"
              @blur="$v.item.address.zipCode.$touch()"
              @input="$v.item.address.zipCode.$touch()"
              required
              v-model="item.address.zipCode"
            />
          </v-col>
          <v-col cols="12" md="6" sm="8">
            <v-text-field
              :error-messages="cityErrors"
              :label="$t('city')"
              @blur="$v.item.address.city.$touch()"
              @input="$v.item.address.city.$touch()"
              v-model="item.address.city"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" sm="6">
            <v-text-field
              :label="$t('country')"
              v-model="defaultCountry"
              disabled="disabled"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn class="ml-auto" color="primary" @click="submitItem">{{
          $t("Submit")
        }}</v-btn>
        <v-btn class="ml-sm-2 mainAccent lighten-3" dark @click="resetItem">{{
          $t("Reset")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import has from "lodash/has";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import InputEditor from "@/components/InputEditor";

export default {
  name: "SpeakerForm",
  mixins: [validationMixin],
  components: { InputEditor },
  props: {
    values: {
      type: Object,
      required: true,
    },

    errors: {
      type: Object,
      default: () => {},
    },

    loading: {
      type: Boolean,
      required: true,
    },

    initialValues: {
      type: Object,
      default: () => {},
    },

    handleSubmit: {
      type: Function,
      required: false,
    },

    handleReset: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      showPassword: false,
      email: null,
      plainPassword: null,
      profile: null,
      enabled: true,
      locked: true,
      typeValues: [
        { text: this.$t("privateAddress"), value: "private" },
        { text: this.$t("businessAddress"), value: "business" },
        { text: this.$t("invoiceAddress"), value: "invoice" },
        { text: this.$t("deliveryAddress"), value: "delivery" },
      ],
      defaultCountry: 'DE',
    };
  },
  computed: {
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },
    emailErrors() {
      const errors = [];

      if (!this.$v.item.email.$dirty) {
        return errors;
      }

      has(this.violations, "email") && errors.push(this.violations.email);

      !this.$v.item.email.required && errors.push(this.$t("Field is required"));

      return errors;
    },
    vitaErrors() {
      const errors = [];

      if (!this.$v.item.vita.$dirty) {
        return errors;
      }

      has(this.violations, "vita") && errors.push(this.violations.vita);

      return errors;
    },
    enabledErrors() {
      const errors = [];

      if (!this.$v.item.enabled.$dirty) {
        return errors;
      }

      has(this.violations, "enabled") && errors.push(this.violations.enabled);

      return errors;
    },
    typeErrors() {
      const errors = [];

      if (!this.$v.item.address.type.$dirty) {
        return errors;
      }

      has(this.violations, "type") && errors.push(this.violations.type);

      !this.$v.item.address.type.required &&
        errors.push(this.$t("Field is required"));

      return errors;
    },
    streetNrErrors() {
      const errors = [];

      if (!this.$v.item.address.streetNr.$dirty) {
        return errors;
      }

      has(this.violations, "streetNr") &&
        errors.push(this.violations.streetNr);

      !this.$v.item.address.streetNr.required &&
        errors.push(this.$t("Field is required"));

      return errors;
    },
    additionalErrors() {
      const errors = [];

      if (!this.$v.item.address.additional.$dirty) {
        return errors;
      }
      has(this.violations, "additional") &&
        errors.push(this.violations.additional);

      return errors;
    },
    zipCodeErrors() {
      const errors = [];

      if (!this.$v.item.address.zipCode.$dirty) {
        return errors;
      }
      has(this.violations, "zipCode") &&
        errors.push(this.violations.zipCode);
      !this.$v.item.address.zipCode.required &&
        errors.push(this.$t("Field is required"));

      return errors;
    },
    cityErrors() {
      const errors = [];

      if (!this.$v.item.address.city.$dirty) {
        return errors;
      }
      has(this.violations, "city") && errors.push(this.violations.city);
      !this.$v.item.address.city.required &&
        errors.push(this.$t("Field is required"));

      return errors;
    },
    violations() {
      return this.errors || {};
    },
  },
  methods: {

    log(m){console.log(m)},
    submitItem() {
      if (this.handleSubmit) {
        this.handleSubmit();
      }
    },
    resetItem() {
      if (this.handleReset) {
        this.handleReset();
      }
    },
  },
  validations: {
    item: {
      email: {
        required,
      },
      vita: {},
      enabled: {},
      profile: {},
      address: {
        type: {
          required,
        },
        streetNr: {
          required,
        },
        additional: {},
        zipCode: {
          required,
        },
        city: {
          required,
        },
      },
    },
  },
};
</script>
